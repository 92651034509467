import React from 'react';
import { useLocation } from 'react-router-dom';
import './ConfirmationPage.css';
import ReservationImage from '../kanryou-form.png';

const ConfirmationPage = () => {
  const location = useLocation();
  const { company,name, email, date, time, course } = location.state || {}; // デフォルト値を設定
  console.log('受信したデータ:', { company,name, email, date, time, course });

  return (
    <form>
      <img src={ReservationImage} alt="予約完了のロゴ" className="form-header-image" />
      {name && date && time && course ? (
        <>
          <p>会社名: {company}</p>
          <p>名前: {name}</p>
          <p>メールアドレス: {email}</p>
          <p>日付: {date}</p>
          <p>時間: {time}</p>
          <p>相談内容: {course}</p>
          <br />
          <p>予約完了のメールが送信されますので、<br />ご確認よろしくお願いします。</p>
        </>
      ) : (
        <p>予約情報が見つかりませんでした。</p>
      )}
    </form>
  );
};

export default ConfirmationPage;
