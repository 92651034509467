import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import ReservationImage from '../reservation-form.png';

const ReservationForm = () => {
  const [company, setCompany] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [course, setCourse] = useState('');
  const [availableTimes, setAvailableTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false); // 予約送信中の状態
  const navigate = useNavigate();

  const fetchAvailableTimes = useCallback((selectedDate) => {
    setLoading(true);
    const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
    
    fetch(`https://l1f22jda1m.execute-api.ap-northeast-1.amazonaws.com/stage/get-available-times`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ date: formattedDate }),
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then((data) => {
      setAvailableTimes(data.free_slots || []);
    })
    .catch((error) => {
      console.error('Error fetching available times:', error);
    })
    .finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (date) {
      fetchAvailableTimes(date);
    }
  }, [date, fetchAvailableTimes]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {
      company,
      name,
      email,
      date,
      time,
      course,
    };

    console.log('送信するデータ:', data);

    setSubmitting(true); // 予約送信中の状態をtrueに設定

    fetch('https://l1f22jda1m.execute-api.ap-northeast-1.amazonaws.com/stage/reserve', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Reservation failed');
    })
    .then((data) => {
      // 予約が成功した場合、確認ページに移動
      navigate('/confirmation', { state: { company, name, email, date, time, course } });
    })
    .catch((error) => {
      console.error('Error:', error);
    })
    .finally(() => {
      setSubmitting(false); // 送信処理が完了したら、送信中の状態をfalseに設定
    });
  };

  if (submitting) { // 予約送信中の場合、フォームを非表示にしてメッセージと進行状況バーのみを表示
    return (
      <div>
        <p>予約確定中・・・</p>
        <progress style={{ width: '100%' }}></progress>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <img src={ReservationImage} alt="予約フォームのロゴ" className="form-header-image" />
      <label>
      会社名:
      <input type="text" value={company} onChange={(e) => setCompany(e.target.value)} required />
      </label>
      <br />
      <label>
        名前:
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
      </label>
      <br />
      <label>
        メールアドレス:
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
      </label>
      <br />
      <label>
        日付:
        <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
      </label>
      <br />
      <label>
        時間:
        {loading ? (
          <p>空いている時間を確認中...</p>
        ) : (
          <select value={time} onChange={(e) => setTime(e.target.value)} required>
            {availableTimes.length > 0 ? (
              availableTimes.map((availableTime) => (
                <option key={availableTime} value={availableTime}>
                  {availableTime}
                </option>
              ))
            ) : (
              <option value="" disabled>空いている時間がありません</option>
            )}
          </select>
        )}
      </label>
      <br />
      <label>
        相談内容:
        <select value={course} onChange={(e) => setCourse(e.target.value)} required>
          <option value="">--選択してください--</option>
          <option value="案件のご紹介">案件のご紹介</option>
          <option value="キャリア相談">キャリア相談</option>
          <option value="友人・エンジニアのご紹介">友人・エンジニアのご紹介</option>
          <option value="その他">その他</option>
        </select>
      </label>
      <br />
      <button type="submit">予約する</button>
    </form>
  );
};

export default ReservationForm;
