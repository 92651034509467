import './App.css';
import { Routes, Route } from 'react-router-dom'; 
import ReservationForm from './Components/ReservationForm'; 
import ConfirmationPage from './Components/ConfirmationPage';

function App() {
  return (
    <div>
      <Routes>
        {/* 予約フォーム */} 
        <Route path="/" element={<ReservationForm />} />
        {/* 予約完了ページ */}
        <Route path="/confirmation" element={<ConfirmationPage />} />
      </Routes>
    </div>
  );
}

export default App;
